<template>
  <div>
    <div class="header">拍卖云卖家版手机端使用教程</div>
    <div class="one-tip">
      <div class="tip-title">1.1登录方式</div>
      <div class="tip-content">
        <span class="tip-content-text">
          微信长按识别小程序<br />
          或微信扫一扫识别小程序（右图）
        </span>
        <img
          src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/8b081a55-4d14-49c7-9d2c-ca2032399386"
        />
      </div>
    </div>
    <div class="two-tip">
      <div class="tip-title">1.2如何上传拍品</div>
      <div class="tip-content">
        <div class="tip-content-text-step1">
          <div class="tip-content-text">
            <span>第一步 </span>点击上传拍品 (如右图）<br />
            需要准备：<br />
            1.作品高清图<br />
            2.作品高清细节图<br />
            3.作品尺寸<br />
            4.作品创作年代、材质（选填）
          </div>
          <div class="tip-content-img">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/226256a5-098e-45d4-934f-32b9382ee044"
            />
          </div>
        </div>
        <div class="clearfix">
          <div class="tip-content-text">
            <span>第二步</span>
            完善拍品信息<br />
            ①填写拍品名称<br />
            ②填写拍品介绍<br />
            ③点击上传图片<br />
            ④修改时间<br />
            ⑤设置起拍价、加价幅度<br />
          </div>
          <div class="tip-content-text" style="margin-top:21px;">
            ⑥设置分类<br />
            ⑦高级设置<br />
            ⑧发布拍品 (如下图）
          </div>
        </div>
        <div class="tip-content-text-step3 clearfix">
          <div class="tip-content-img img-float-margin">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/d43d7c1b-59fe-4b02-ba16-f667ce421a8c"
            />
          </div>
          <div class="tip-content-img2">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/c8727897-e264-4242-9408-502ca62dce3d"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="three-tip">
      <div class="tip-title">1.3如何组建拍场</div>
      <div class="tip-content">
        <div class="tip-content-text-step1 clearfix">
          <div class="tip-content-img img-float-margin ">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/bbcbad48-d795-4c1c-9ee1-c59831193824"
            />
          </div>

          <div class="tip-content-text">
            <span>第一步</span> 点击组建专场<br />
            (如左图）<br />
            需要准备：<br />
            1.专场海报
          </div>
        </div>
        <div class="tip-content-text-step2">
          <div class="tip-content-text">
            <span>第二步 </span> 完善拍场信息<br />
            ①填写专场名称<br />
            ②填写专场介绍<br />
            ③修改时间<br />
            ④上传专场图片<br />
            ⑤添加已有拍品或创建新拍品<br />
            ⑥发布拍场 (如右图）
          </div>
          <div class="tip-content-img">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/f6733683-f598-42da-8654-f7596067d3dc"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="four-tip">
      <div class="tip-title">1.4我要提现</div>
      <div class="tip-content">
        <div style="" class="tip-content-text-step1">
          <div class="tip-content-text">
            <span>第一步 </span>
            点击我要提现<br />（如右图）<br />
            （注：消费者收到货的20天<br />可提现）
          </div>
          <div class="tip-content-img">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/90abd662-1f4f-488c-8592-944f4acd8b63"
            />
          </div>
        </div>
        <div class="tip-content-text-step2 clearfix">
          <div class="tip-content-img img-float-margin">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/51ce7204-6ca1-453b-a753-7bf3bf327f79"
            />
          </div>
          <div class="tip-content-text">
            <span>第二步<br /> </span>
            点击我要提现即可提现<br />（如左图）
          </div>
        </div>
        <div class="tip-content-text-step3">
          <div class="tip-content-img img-float-margin">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/8bd60096-d21e-4176-9e39-6355312210ae"
            />
          </div>
          <div class="tip-content-text">
            <span>第三步 </span><br />
            在提现列表—已提现<br />中查看已经提现的订单<br />（如左图）
          </div>
        </div>
        <div class="tip-content-text-step4">
          <div class="tip-content-text">
            <span>第四步 </span>
            在订单结算—未结束<br />中查看未提现的订单<br />（如右图）
          </div>
          <div class="tip-content-img">
            <img
              src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/add02641-4126-4654-bde1-523f5c3550d0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted: function() {
    document.title = "拍卖云卖家版手机端使用指南";
  },
};
</script>
<style>
.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  *zoom: 1; /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}
.header {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-top: 50px;
  opacity: 0.9;
}
.tip-title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-align: left;
  margin-left: 5px;
  opacity: 0.8;
}
.tip-content-text {
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #000000;
  text-align: left;
  line-height: 25px;
  opacity: 0.8;
}
.tip-content-text span {
  color: #ff0000;
}
.img-float-margin {
  float: left;
  margin-left: 2%;
}
.one-tip {
  margin-top: 48px;
  margin-bottom: 10px;
}
.one-tip .tip-content {
  margin: 22px 0 0 7px;
}
.one-tip .tip-content-text {
  float: left;
  padding: 35px 0;
}
.one-tip img {
  width: 125px;
  height: 125px;
  margin-left: 2px;
  vertical-align: middle;
}
.two-tip .tip-content-text {
  margin-left: 7px;
  float: left;
}
.two-tip .tip-content-text-step1 {
  margin-bottom: 9px;
}
.two-tip .tip-content-text-step1 .tip-content-text {
  margin-top: 54px;
}
.two-tip .tip-content-text-step1 .tip-content-img img {
  margin-left: 17px;
}
.two-tip .tip-content-text-step3 {
  margin-top: 10px;
}
.three-tip .tip-content-text-step1 {
  margin-top: 20px;
}
.two-tip .tip-content-text-step3 .tip-content-img2 img {
  margin-left: 85px;
}
.three-tip .tip-title {
  margin-top: 20px;
}
.three-tip .tip-content-text-step1 img {
  margin-right: 45px;
}
.three-tip .tip-content-text-step1 .tip-content-text {
  padding-top: 65px;
}
.three-tip .tip-content-text-step2 .tip-content-text,
.four-tip .tip-content-text-step4 .tip-content-text {
  float: left;
  margin-top: 40px;
  margin-left: 7px;
}
.three-tip .tip-content-text-step2 .tip-content-img img {
  margin-left: 25px;
}
.four-tip .tip-title {
  margin-top: 20px;
}
.four-tip .tip-content-text-step1,
.four-tip .tip-content-text-step2,
.four-tip .tip-content-text-step3,
.four-tip .tip-content-text-step4 {
  margin-top: 10px;
}
.four-tip .tip-content-text-step1 .tip-content-text {
  padding-top: 70px;
  margin-left: 7px;
  float: left;
}
.four-tip .tip-content-text-step1 .tip-content-img img {
  margin-left: 40px;
}
.four-tip .tip-content-text-step2 .tip-content-img {
  margin-right: 40px;
}
.four-tip .tip-content-text-step2 .tip-content-text {
  padding-top: 105px;
}
.four-tip .tip-content-text-step3 .tip-content-text {
  padding-top: 30px;
}
.four-tip .tip-content-text-step3 {
  overflow: hidden;
}
.four-tip .tip-content-text-step4 {
  margin-bottom: 80px;
}
.four-tip .tip-content-text-step3 .tip-content-img {
  height: 120px;
  margin-right: 45px;
}
.four-tip .tip-content-text-step4 .tip-content-text {
  margin-right: 35px;
}
.four-tip .tip-content-text-step4 .tip-content-img {
  height: 120px;
}

.two-tip img,
.three-tip img,
.four-tip img {
  width: 125px;
}
</style>
