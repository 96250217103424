import Vue from 'vue'
import App from './App.vue'
import auctionCloudSellerPhoneHelp from './components/auctionCloudSellerPhoneHelp/auctionCloudSellerPhoneHelp'
import auctionCloudSellerPcHelp from './components/auctionCloudSellerPcHelp/auctionCloudSellerPcHelp'
Vue.config.productionTip = false

// 导入 vue router
import VueRouter from "vue-router";
// 告诉vue使用vue-router路由组件
Vue.use(VueRouter);

// 定义路由表
const routes = [
  {
    path: "/sellerphonehelp",
    component: auctionCloudSellerPhoneHelp,
  },
  {
    path: "/sellerpchelp",
    component: auctionCloudSellerPcHelp,
  },
];
// 创建路由器实例，并且传入`routes`变量作为路由。
// 你还可以传入别的参数，不过在这里尽量简单化就可以了
const router = new VueRouter({
  routes,
  mode: "history",
});
// 实例化Vue实例
new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
// 将这个实例挂载到id=app的根元素上