<template>
  <div class="warp">
    <div class="header-pc">
      <span class="web-mark">拍卖云</span>
      <span class="web-title">卖家版PC端使用教程</span>
    </div>
    <div class="main">
      <div class="one-tip-pc">
        <div class="tip-title-pc">
          <span>1.1 打开拍卖云卖家版PC端 </span>
          <span class="auction-cloud-href"
            >网址：<a href="http://seller.smzdpm.com/index/info"
              >http://seller.smzdpm.com/index/info</a
            >（谷歌浏览器）</span
          >
        </div>
        <div class="tip-picture">
          <img
            src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/c80330bc-b722-4a05-a194-586d1c7a21e3"
          />
        </div>
      </div>
      <div class="two-tip-pc">
        <div class="tip-title-pc">1.2 新建拍品操作步骤</div>
        <div class="tip-picture">
          <img
            src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/13f561f4-43b5-482e-9138-e575ac272f78"
          />
        </div>
      </div>
      <div class="three-tip-pc">
        <div class="tip-title-pc">1.3 上传拍品操作步骤</div>
        <div class="tip-picture">
          <img
            src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/86d7c5fd-2978-4470-87b0-14c8b9e6a2c2"
          />
        </div>
      </div>
      <div class="four-tip-pc">
        <div class="tip-title-pc">1.4 新建拍场</div>
        <div class="tip-picture">
          <img
            src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/e746b781-0be8-46ff-a5fa-c4a3b2e5f29d"
          />
        </div>
      </div>
      <div class="five-tip-pc">
        <div class="tip-title-pc">1.5 组建拍场</div>
        <div class="tip-picture">
          <img
            src="http://auctioncloudproduct.oss-cn-beijing.aliyuncs.com/7d28cc97-a267-4540-a1b7-184c3e802702"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted: function() {
    document.title = "拍卖云卖家版电脑端使用指南";
  },
  beforeRouteEnter(to, from, next) {
    // 添加背景色
    document
      .querySelector("body")
      .setAttribute(
        "style",
        "background-color:#f9f9f9;margin-top:0;margin-right:0;"
      );
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector("body").setAttribute("style", "");
    next();
  },
};
</script>
<style>
a {
  color: blue;
}
/* a:visited {
  color: #551A8B;
} */
.header-pc {
  font-size: 25px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  padding-left: 22px;
  padding-bottom: 10px;
  background-color: #fff;
  padding-top: 18px;
}
.web-mark {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  margin-right: 5px;
}
.web-title {
  border-left: 3px solid #000;
  padding-left: 5px;
}
.main {
  text-align: left;
}
.tip-picture img {
  width: 98%;
}
.tip-picture {
  text-align: center;
  vertical-align: middle;
}
.tip-title-pc {
  font-size: 26px;
  font-weight: 600;
  margin-left: 40px;
  color: #000;
}
.one-tip-pc .tip-title-pc,
.two-tip-pc .tip-title-pc {
  margin-top: 32px;
}
.one-tip-pc .tip-title-pc,
.two-tip-pc .tip-title-pc,
.four-tip-pc .tip-title-pc {
  margin-bottom: 19px;
}
.one-tip-pc .tip-picture img {
  width: 94.5%;
}
.auction-cloud-href {
  font-weight: 400;
}
</style>
